import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/global/layout';
import Seo from 'components/global/seo';
import Blog from '../components/blog/blog';

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <Blog data={data} />
    </Layout>
  );
};

export const Head = () => <Seo title='Blog' />;

export default BlogPage;

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            tags
            featuredDate
            featuredImage {
              childImageSharp {
                gatsbyImageData(aspectRatio: 2)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
