import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import 'styles/pages/blog/main.scss';

const Blog = ({ data }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [posts, setPosts] = useState([]);

  const getTags = () => {
    const allTags = data.allMarkdownRemark.edges
      .map(({ node }) => {
        return node.frontmatter.tags;
      })
      .join(' ')
      .split(' ');

    const tagsArray = Array.from(new Set(allTags));

    const tags = tagsArray.map((tag) => {
      const active = selectedTags.includes(tag);
      return { tag, active };
    });

    return tags;
  };

  const tagClickHandler = (e) => {
    const selectedTag = e.target.innerText;

    setSelectedTags((currentTags) => {
      if (currentTags.includes(selectedTag)) {
        const newFilteredTags = currentTags.filter(
          (tag) => tag !== selectedTag
        );
        return newFilteredTags;
      } else {
        return [...currentTags, selectedTag];
      }
    });
  };

  useEffect(() => {
    const posts = data.allMarkdownRemark.edges.filter(({ node }) => {
      const postTags = node.frontmatter.tags.split(' ');

      let hasTags = false;
      if (!selectedTags.length) return true;

      selectedTags.forEach((tag) => {
        if (hasTags) return;
        hasTags = postTags.includes(tag);
      });

      return hasTags;
    });
    setPosts(posts);
  }, [selectedTags, data.allMarkdownRemark.edges]);

  return (
    <div className='blog-all'>
      <div className='blog-container'>
        <h1 className='blog-title'>
          <span className='blue'>Discover posts </span>
          by the Mobile team!
        </h1>
        <div className='blog-tags'>
          {getTags().map(({ tag, active }, index) => (
            <button
              key={index}
              className={active ? 'blog-tag active' : 'blog-tag'}
              onClick={tagClickHandler}
            >
              {tag}
            </button>
          ))}
        </div>
        <h4>
          {posts.length} post{posts.length > 1 ? 's' : ''}
        </h4>
        <div className='blog-posts'>
          {posts.map(({ node }) => {
            let featuredImg = getImage(
              node.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData
            );
            return (
              <div key={node.id} className='blog-post'>
                <Link to={node.fields.slug}>
                  <GatsbyImage image={featuredImg} />
                  <div className='content'>
                    <h3 className='title'>{node.frontmatter.title}</h3>
                    <p className='date'>{node.frontmatter.featuredDate}</p>
                    {node.frontmatter.tags.split(' ').map((tag, index) => {
                      return (
                        <span key={index} className='blog-tag'>
                          {tag}
                        </span>
                      );
                    })}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Blog;
